figure {
    display: inline-block;
    width: 75px;
    margin: 25px;
  }

  figcaption {
    font-style: oblique;
    text-align: center;
  }

  .imagen img {
    height: 80px;
    max-width: 100%;
    cursor: pointer;
  }

  .modalImg {
    cursor: pointer;
    border-radius: 0.4rem;
    /* max-width: 100px; */
  }

  .imodalImg:hover {
    opacity: 0.8;
  }

  .modal {
    display: none;
    /* ocultar por defecto */
    position: fixed;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border: solid 1px;
    background: rgba(0, 0, 0, 0.9);
    padding-top: 5%;
    overflow: hidden;
  }

  #modalImg {
    display: block;
    position: fixed;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* center to window */
    height: 580px;
  }

  #modalClose {
    position: absolute;
    cursor: pointer;
    top: 30px;
    right: 50px;
    color: #fff;
    transition: all;
    font-weight: bold;
    font-size: 5rem;
  }

  #modalText {
    margin: auto;
    display: block;
    text-align: center;
    color: #ccc;
  }

  /* animaciones */

  #modalText {
    animation-name: zoom;
    animation-duration: 0.5s;
  }

  @keyframes zoom {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }

  @media (min-width: 320px) and (max-width: 470px)  {
 
    #modalImg {
        display: block;
        position: fixed;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* center to window */
        height: 440px;
    }
} 