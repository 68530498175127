*{
    padding: 0;
    margin: 0;
}

.Header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 20vh;
    /* background: radial-gradient(circle, #fed4d5, wheat ); */
    background-color: #fed4d5;
}

.Logo{
    height: 340px;
    width: 30%;
}

.lema{
    background: linear-gradient(gray, #b9d1c3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px gray);
    font-size: 60px;
    font-family: 'Dancing Script', cursive;
}

@media (min-width: 320px) and (max-width: 480px)  {
    .Header{
        display: flex;
        flex-direction: column;
        height: 280px;
    }

    .Logo{
        width: 80%;
        margin-top: -20%;
    }

    .lema{
        margin-top: -20%;
    }
}

@media (min-width: 480px) and (max-width: 600px)  {
    .Header{
        display: flex;
        flex-direction: column;
        height: 280px;
    }

    .Logo{
        width: 80%;
        margin-top: -20%;
    }

    .lema{
        margin-top: -20%;
    }
}

@media (min-width: 600px) and (max-width: 750px)  {
    .Header{
        display: flex;
        flex-direction: column;
        height: 280px;
    }

    .Logo{
        width: 80%;
        margin-top: -20%;
    }

    .lema{
        margin-top: -20%;
    }
}

@media (min-width: 750px) and (max-width: 900px)  {
    .Header{
        display: flex;
        flex-direction: column;
        height: 280px;
    }

    .Logo{
        width: 80%;
        margin-top: -20%;
    }

    .lema{
        margin-top: -20%;
    }
}