.card{
    width: 1% !important;
    margin-left: 1310px;
    /* scroll-behavior: smooth !important */
    position: fixed !important;
    z-index: 500 !important
}

.p-sidebar {
    height: 400px !important;
}

.p-button{
    background-color: rgb(19, 144, 175) !important;
}

a{
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480px)  {
    .card{
        margin-left: 365px;
    }

    .imgRedes{
        height: 50px;
    }

    .p-sidebar {
        height: 300px !important;
    }

    a{
        text-decoration: none;
    }
}

@media (min-width: 480px) and (max-width: 600px)  {
    .card{
        margin-left: 425px;
    }

    .imgRedes{
        height: 50px;
    }

    .p-sidebar {
        height: 300px !important;
    }

    a{
        text-decoration: none;
    }
}

@media (min-width: 600px) and (max-width: 750px)  {
    .card{
        margin-left: 565px;
    }

    .imgRedes{
        height: 50px;
    }

    .p-sidebar {
        height: 300px !important;
    }

    a{
        text-decoration: none;
    }
}

@media (min-width: 750px) and (max-width: 900px)  {
    .card{
        margin-left: 700px;
    }

    .imgRedes{
        height: 50px;
    }

    .p-sidebar {
        height: 300px !important;
    }

    a{
        text-decoration: none;
    }
}