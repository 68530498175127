*{
    padding: 0;
    margin: 0;
}

.carouselDemoContainer{
    /* background: radial-gradient(circle, #fed4d5, wheat ); */
    border: solid 4px rgb(64, 128, 128);
    border-radius: 05px;
}

.home{
    /* background: radial-gradient(circle, #fed4d5, wheat ); */
    background-color: #fed4d5;
    padding-top: 2%;
    padding-bottom: 2%;
}


@media (min-width: 320px) and (max-width: 480px)  {
    .carouselDemoContainer{
        height: 300px;
    }

    img{
        height: 250px;
    }
}

@media (min-width: 480px) and (max-width: 600px)  {
    .carouselDemoContainer{
        height: 300px;
    }

    img{
        height: 250px;
    }
}

@media (min-width: 600px) and (max-width: 750px)  {
    .carouselDemoContainer{
        height: 350px;
    }

    img{
        height: 300px;
    }
}

@media (min-width: 750px) and (max-width: 900px)  {
    .carouselDemoContainer{
        height: 400px;
    }

    img{
        height: 350px;
    }
}