.cartaBlusa1{
    display: flex;
    flex-direction: column;
    /* width: 15%; */
    background-color: white;
    border: solid 10px white;
    border-radius: 05%;
    padding-top: 2%;
}

.containerCartas{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.fotosBlusas{
    height: 170px;
    width: 70%;
    margin-left: 15%;
}

.botonContactar{
    background-color: #15b4ac;
}

@media (min-width: 320px) and (max-width: 480px)  {
    .containerCartas{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .cartaBlusa1{
        width: 85%;
        margin-left: 8%;
    }

    #modalImg {
        display: block;
        position: fixed;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* center to window */
        height: 440px;
    }
}


@media (min-width: 480px) and (max-width: 600px)  {
    .containerCartas{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .cartaBlusa1{
        width: 85%;
        margin-left: 8%;
    }

    #modalImg {
        display: block;
        position: fixed;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* center to window */
        height: 440px;
    }
}

@media (min-width: 600px) and (max-width: 750px)  {
    .containerCartas{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .cartaBlusa1{
        width: 75%;
        margin-left: 8%;
    }

    #modalImg {
        display: block;
        position: fixed;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* center to window */
        height: 440px;
    }
}

@media (min-width: 750px) and (max-width: 900px)  {
    .containerCartas{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .cartaBlusa1{
        width: 55%;
        margin-left: 25%;
    }

    #modalImg {
        display: block;
        position: fixed;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* center to window */
        height: 440px;
    }
}